












































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Store
import { userNamespace } from '@store/user'

// Types
import { UserActionTypes } from '@store/user/Types'
import { TaskActions, TaskMutations } from '@/store/task/types'

// Interfaces
import { ITask } from '@/interfaces/Interface'
import { Task } from '@store/task/interfaces'
import { Paginate, RequestParams } from '@/store/interfaces'

// Modules
const NSTask = namespace('taskModule')

@Component({
  name: 'TaskOut',

  components: {
    'v-form-change': () => import('@/components/forms/FormChange.vue'),
    'v-dialog-task-info': () => import('@/components/dialogs/DialogTaskInfo.vue'),
    'v-dialog-task-execute': () => import('@/components/dialogs/DialogTaskExecute.vue'),
  },
})
export default class TaskOutComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  private task!: Task

  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @NSTask.Action(TaskActions.A_FETCH_TASKS)
  private fetchTasks!: (params?: RequestParams) => Promise<Paginate<Task>>

  @NSTask.Mutation(TaskMutations.M_CLEAR_TASKS)
  private clearTasks!: () => void

  @userNamespace.Action(UserActionTypes.A_FINISH_TASK_OUT)
  private finishTask!: (params) => Promise<any>

  private visibleDialogTaskInfo: boolean = false
  private visibleDialogTaskExecute: boolean = false

  // private get author(): string {
  //   if (this.task.author.length) {
  //     const author = this.task.author.split(' ')

  //     return `${author[0]} ${author[1][0]}. ${author[2][0]}.`
  //   } else {
  //     return ''
  //   }
  // }

  private handleCompleteTask() {
    this.finishTask({ taskID: this.task.id, selectedProject: this.selectedProject })
      .then(() => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })

        this.clearTasks()
        this.fetchTasks({
          projectId: this.selectedProject,
        })
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
